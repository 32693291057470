import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import { Title } from 'react-head';
import { Link } from 'gatsby';
import style from './search.module.scss';
import {Index} from 'lunr';
import { Icon } from '../components/icon';

const lunr = require('lunr');
const title = 'Search';
const historyMock = {
  state: {},
  replaceState: () => { },
  back: () => { },
};

const history = typeof window !== `undefined` ? window.history : historyMock;

const Search = () => {
  // todo: Fix thses anys below
  const [index, setIndex] = useState<null | Index>(null);
  const [searchMeta, setSearchMeta] = useState({});
  const [searchText, setSearchText] = useState(history.state && history.state.search ? history.state.search : '');
  const [results, setResults] = useState<any []>([]);
  const inputRef = useRef<null | any>(null);

  useEffect(() => {
    var req = new XMLHttpRequest();
    req.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        const searchIndex = JSON.parse(this.responseText);
        const index = lunr.Index.load(searchIndex.lunr);
        setIndex(index);
        setSearchMeta(searchIndex.meta);
      }
    };
    req.open(`GET`, `/search_index.json`, true);
    req.send();

    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (index && searchMeta) {
      let results: any[] = [];
      if (searchText.length < 4) {
        results = index.search(searchText + '*');
      } else {
        results = index.search(searchText);
        if (results.length === 0) {
          results = index.search(searchText + '*');
        }
        if (results.length === 0) {
          results = index.search(searchText + '~3');
        }
      }
      const mappedResults = results
        .filter(res => searchMeta[+res.ref])
        .map(res => searchMeta[+res.ref]);
      setResults(mappedResults);
      history.replaceState({ search: searchText }, title);
    }
  }, [index, searchMeta, searchText]);

  return (
    <section className={style.searchContainer}>
      <Title>{title}</Title>
      <div className={style.searchBar}>
        <Icon >search</Icon>
        <input id="search" type="text" placeholder="Start typing for results..." value={searchText} onChange={e => setSearchText(e.target.value)} ref={inputRef} />
        <button onClick={() => history.back()} title="Close Search">&#x2716;</button>
      </div>
      <div className={style.resultsContainer}>
        {results.map(r => (
          <article key={r.id}>
            <div>
              <Link className={style.resultLink} to={r.url}>
                <div>
                  <img className={style.resultImage} src={r.image} alt={r.description} />
                </div>
                <div className={style.summaryContainer}>
                  <p className={`${style.title} h4`}>{r.title}</p>
                  <p>{r.description}</p>
                </div>
              </Link>
            </div>
          </article>
        ))}
      </div>
    </section>);
}

export default Search;
